<template>
  <div v-if="getOrderTasksByTypeArr(['zb_money_essential', 'zb_money_pro', 'expense_tracking', 'expense_tracking_with_tax_filing', 'tax_filing'])" class="za">
    <h2 class="header">ZB Money Essential/Pro</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <div class="row mt-3">
            <div class="col d-flex justify-content-start align-items-center">
              <h3>Account Data</h3>
            </div>
            <div class="col d-flex justify-content-end">
              <ZgButton class="zg-btn-default zg-text-sm mx-2"
                v-if="getLastOrderExpenseTracking"
                @click="showCustomerAssetMigrationModal=true"
              >
                <template v-slot:title>{{ titleMigrateBtn }}</template>
              </ZgButton>
              <ZgButton class="zg-btn-danger zg-text-sm"
                v-if="getMigrationInformation && !isEtfSubscriptionExpired"
                @click="showCancelMigrationModal=true"
              >
                <template v-slot:title>Cancel Migration</template>
              </ZgButton>
            </div>
          </div>
          <div class="row mt-3 mx-2" v-if="zbMoneyAccountData">
            <div class="col col-4">
              <strong>Account Type</strong>
              <br/>
              {{ accountType }}<p class="zg-pending-text" v-show="getMigrationInformation"> (pending migration)</p>
            </div>
            <div class="col col-4">
              <strong>Interval</strong>
              <br/>
              {{ makeSentenceCase(zbMoneyAccountData.interval) }}
            </div>
            <div class="col col-4">
              <strong>Plaid Linked</strong>
              <br/>
              {{ zbMoneyAccountData.is_plaid_linked | yesno }}
            </div>
          </div>
          <div class="row mt-3" v-else>
            <div class="col">
              <em>A Hurdlr subscription has not been created</em>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="row mt-3">
            <h3>Order Tasks</h3>
          </div>
          <div class="row mt-3 mx-3">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Pricing</th>
                  <th>Term</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Billing</th>
                  <th>Salesforce</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="zbMoneyOrderTasks">
                  <tr v-for="zbMoney in sortedZbMoneyOrderTasks" :key="zbMoney.order_task_uuid">
                    <td>{{ zbMoney.order_task_type }}</td>
                    <td>{{ zbMoney.billing ? `$${zbMoney.billing.price}` : '' }}</td>
                    <td>{{ (zbMoney.billing ? zbMoney.billing.period : '') | readableRecurringPeriod }}</td>
                    <td>
                      {{ toMoment(zbMoney.term_start_date, 'MM/DD/YYYY') }}
                      <span v-if="zbMoney.term_start_date && zbMoney.term_end_date"> - </span>
                      {{ toMoment(zbMoney.term_end_date, 'MM/DD/YYYY') }}
                    </td>
                    <td>
                      <span class="badge" :class="[getOrderTaskStatusBg(zbMoney.order_task_status)]">
                        {{ makeSentenceCase(zbMoney.order_task_status) }}
                      </span>
                    </td>
                    <td><router-link :to="{ name: 'OrderBillingView', params: { uuid: zbMoney.order_uuid } }">Billing</router-link></td>
                    <td><a :href="opportunitySalesforceUrl(zbMoney.customer_asset_sf_id)" target="_blank">Salesforce Asset</a></td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="getMigrationInformation">
        <div class="col">
          <div class="row">
            <div class="col d-flex justify-content-start align-items-center">
              <h3>Migration Preference</h3>
            </div>
          </div>
          <div class="row mt-3 mx-2">
            <div class="col col-6">
              <strong>Migrate From</strong>
              <br/>
              {{ getMigrationInformation.migrate_from | capitalizeAllWordsAndReplaceUnderscore }}
            </div>
            <div class="col col-5">
              <strong>Migrate To</strong>
              <br/>
              {{ getMigrationInformation.migrate_to.service | capitalizeAllWordsAndReplaceUnderscore }} - {{ getMigrationInformation.migrate_to.interval  | capitalizeAndReplaceUnderscore }}
            </div>
          </div>
          <div class="row mt-3 mx-2">
            <div class="col col-6">
              <strong>Planned migration date</strong>
              <br/>
              {{ toMoment(getMigrationInformation.migration_date, 'MM/DD/YYYY') }}
            </div>
            <div class="col col-5">
              <strong>Coupon Applied</strong>
              <br/>
              {{ getMigrationInformation.coupon | yesno }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CustomerAssetsCancelMigrationModal v-if="showCancelMigrationModal"
      @closeModal="showCancelMigrationModal=false"
      @confirmed="confirmedCancelModal"
      :orderTaskUuid="getOrderTaskUUIDFromLastOrderExpenseTracking"
    />
    <CustomerAssetsMigrationModal v-if="showCustomerAssetMigrationModal"
      @closeModal="closeMigrationModal"
      :orderTaskUUID="getOrderTaskUUIDFromLastOrderExpenseTracking"
    />
    
  </div>
</template>
<script>
import { makeSentenceCase } from '@/assets/utils/text-utils';
import { mapActions, mapGetters } from 'vuex';
import { yesNoMixin, momentMixin, capitalizeAndReplaceUnderscoreMixin, readableRecurringPeriodMixin } from '@/components/mixins';
import { ZB_MONEY_ORDER_TASK_TYPES } from '@/v2/zb-money/constants/zb-money-constants';
import CustomerAssetsMigrationModal from '@/v2/zb-money/components/CustomerAssetsMigrationModal.vue';
import CustomerAssetsCancelMigrationModal from '@/v2/zb-money/components/CustomerAssetsCancelMigrationModal.vue';
import manifest from '@/manifest';
import moment from 'moment';
import ZgButton from '@/components/common/Generics/ZgButton.vue';

export default {
  components: { 
    ZgButton,
    CustomerAssetsMigrationModal,
    CustomerAssetsCancelMigrationModal
  },
  
  name: 'CustomerAssetsView',

  props: ['uuid'],

  mixins: [yesNoMixin, momentMixin, capitalizeAndReplaceUnderscoreMixin,readableRecurringPeriodMixin],

  data() {
    return {
      zbMoneyOrderTasks: [],
      showCustomerAssetMigrationModal: false,
      showCancelMigrationModal: false
    }
  },

  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid',
      getOrderTasksByTypeArr: 'orders/getOrderTasksByTypeArr',
      zbMoneyAccountData: 'zbMoney/getZbMoneyAccountData',
      getOrderTask: 'orders/getOrderTask',
    }),
    getMigrationInformation() {
      if (this.getOrderTask===null || !(Object.keys(this.getOrderTask).length) || this.getOrderTask.migration_data === null) return null;
      // {{ getMigrationInformation.migrate_to.related_service_pricing.service | capitalizeAllWordsAndReplaceUnderscore }} - {{ getMigrationInformation.migrate_to.related_service_pricing.interval  | capitalizeAndReplaceUnderscore }}
      const migrationData = this.getOrderTask.migration_data;
      return {
        migrate_from: this.getOrderTask.service_product,
        migrate_to: {
          service: migrationData?.affiliate_pricing.related_service_pricing.service,
          interval: migrationData?.affiliate_pricing.related_service_pricing.interval,
        },
        migration_date: this.getOrderTask.term_end_date,
        coupon: migrationData.coupon,
      }
    },
    isEtfSubscriptionExpired() {
      if (
        this.getOrderTask.service_product !== "expense_tracking_with_tax_filing"
      ) {
        return false;
      }
      const term_end_date = moment(this.getOrderTask.term_end_date);
      return term_end_date.isBefore();
    },
    accountType() {
      if(this.zbMoneyAccountData && this.zbMoneyAccountData.account_type === 'Hurdlr Pro') {
        return 'ZB Money Pro';
      } else if(this.zbMoneyAccountData && this.zbMoneyAccountData.account_type === 'Hurdlr Premium') {
        return 'ZB Money Premium'
      } else {
        return 'Unknown'
      }
    },
    sortedZbMoneyOrderTasks () {
      return this.zbMoneyOrderTasks.slice().sort((a, b) => moment(a.created_datetime).valueOf() - moment(b.created_datetime).valueOf())
    },
    titleMigrateBtn() {
      return this.getMigrationInformation?'Update  Legacy Order':'Migrate Legacy Order';
    },
    getLastOrderExpenseTracking() {
      // This property will retrieve the Order Task to migrate
      const filtered = this.sortedZbMoneyOrderTasks.filter((ot) => { 
        return ot.order_task_status === 'active' && ['expense_tracking_with_tax_filing'].includes(ot.order_task_type);
        });
      if(filtered && filtered.length>0) {
        this.fetchOrderTaskV2({ "orderTaskUuid": filtered[0].order_task_uuid });
        return filtered[0];
      }
      return null;
    },
    getOrderTaskUUIDFromLastOrderExpenseTracking() {
      return this.getLastOrderExpenseTracking?this.getLastOrderExpenseTracking.order_task_uuid:null;
    },
  },
  methods: {
    makeSentenceCase,
    ...mapActions('zbMoney', [
      'fetchZbMoneyStatus', 
      'fetchMigrationServicesOptions', 
      'clearMigrationPreferenceCustomerAssets', 
      'resetZbMoneyState'
    ]),
    ...mapActions('orders', [
      'fetchOrderTasksByAccount', 
      'fetchSubscriptionBillingItems', 
      'fetchCancelAndRefundReasons', 
      'fetchOrderTaskV2'
    ]),
    getZbMoneyOrderTasks () {
      const orderTasks = this.getOrderTasksByTypeArr(ZB_MONEY_ORDER_TASK_TYPES)
      return orderTasks.map( ot => {
        this.fetchSubscriptionBillingItems({uuid: ot.order_uuid, commitToStore: false})
          .then( response => {
            if (Array.isArray(response)) {
              ot.billing = response.find(element => {
                return ZB_MONEY_ORDER_TASK_TYPES.includes(element.service) ? element.service : false;
              })
            }
            this.zbMoneyOrderTasks.push(ot)
          })
      })
    },
    getOrderTaskStatusBg(status) {
      if (status === 'complete' || status === 'active') {
        return 'bg-info'
      } else if (status === 'pending') {
        return 'bg-secondary'
      } else if (status === 'canceled') {
        return 'bg-danger'
      }
      return 'bg-info'
    },
    opportunitySalesforceUrl(customer_asset) {
      return `${manifest.clients.salesforce.host}lightning/r/${customer_asset}/view`;
    },
    async cancelMigrationProcess() {
      this.showCancelMigrationModal=false;
      this.zbMoneyOrderTasks = [];
      await this.fetchOrderTasksByAccount({ accountUuid: this.accountUuid, isCancledExcluded: false })
      this.getZbMoneyOrderTasks();
    },
    async closeMigrationModal() {
      this.showCustomerAssetMigrationModal=false;
      await this.reloadInformation();
    },
    async confirmedCancelModal() {
      this.showCancelMigrationModal=false;
      await this.reloadInformation();
    },
    async reloadInformation() {
      this.zbMoneyOrderTasks = [];
      await this.fetchOrderTasksByAccount({ accountUuid: this.accountUuid, isCancledExcluded: false })
      this.getZbMoneyOrderTasks();
    }
  },

  async created () {
    this.fetchCancelAndRefundReasons();
    this.fetchZbMoneyStatus(this.accountUuid)
    await this.fetchOrderTasksByAccount({ accountUuid: this.accountUuid, isCancledExcluded: false })
    this.getZbMoneyOrderTasks();
    this.clearMigrationPreferenceCustomerAssets();
  },

  beforeDestroy () {
    this.resetZbMoneyState();
  }
};
</script>
<style lang="scss" scoped>
  strong {
    font-size: rem(14);
  }
  .zg-pending-text {
    font-style: italic;
    color: $clr-font-secondary;
    font-size: rem(12);
  }
</style>
