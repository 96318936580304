<template>
  <div>
    <div class="row">
      <h2 class="heading">Create Account</h2>
    </div>
    <div class="row">
      <form>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label v-bind:class="{'text-danger': !validValue(firstName)}" for="first-name">First Name</label>
            <input v-model="firstName" class="form-control" placeholder="John" name="First Name" id="first-name" required />
          </div>
          <div class="form-group col-md-4">
            <label for="middle-name">Middle Name</label>
            <input v-model="middleName" class="form-control" placeholder="(optional)" name="Middle Name" id="middle-name" />
          </div>
          <div class="form-group col-md-4">
            <label v-bind:class="{'text-danger': !validValue(lastName)}" for="last-name">Last Name</label>
            <input v-model="lastName" class="form-control" placeholder="Doe" name="Last Name" id="last-name" required/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label v-bind:class="{'text-danger': !validateEmail(email)}" for="email">Email</label>
            <input v-model="email" class="form-control" type="email" placeholder="john.doe@gmail.com" name="Email" id="email" required/>
          </div>
          <div class="form-group col-md-4">
            <label v-bind:class="{'text-danger': !validValue(password)}" for="password">Password</label>
            <input v-model="password" class="form-control" type="password" placeholder="******" name="Password" id="password" required/>
          </div>
          <div class="form-group col-md-4">
            <label v-bind:class="{'text-danger': !validValue(phone)}" for="phone">Phone</label>
            <input v-model="phone" class="form-control" type="tel" placeholder="123-456-7890" name="Phone" id="phone" required/>
          </div>
        </div>
        <hr/>
        <div class="form-select">
          <label for="account-type">Account Type</label>
          <select id="account-type" class="form-control" v-model="type">
            <option value="null" disabled selected>Please select one</option>
            <option value="standard">Standard Client (Default)</option>
            <option value="reseller">Reseller</option>
            <option value="enterprise-api">Enterprise API</option>
          </select>
        </div>
        <hr>
        <div class="row" v-if="type==='reseller'">
          <div class="form-group col-md-6">
            <label for="biz-name">Self-described name</label>
            <input v-model="self_declared_business_name" class="form-control" placeholder="" name="Name" id="biz-name" />
          </div>
          <div class="form-group col-md-6">
            <label for="biz-type">Self-described type</label>
            <select class="form-control" v-model="self_declared_business_type" id="biz-type">
              <option disabled value="">Please select one</option>
              <option>lawyer</option>
              <option>accountant</option>
              <option>other</option>
            </select>
          </div>
          <div class="form-group col-md-6" v-if="self_declared_business_type==='other'">
            <label for="biz-type-text">Other text</label>
            <input v-model="self_declared_business_type_other_text" class="form-control" placeholder="" name="TypeText" id="biz-type-text" />
          </div>
        </div>
      </form>
    </div>
    <div class="row">
      <button v-on:click="createAccount" class="btn btn-primary">Submit</button>
    </div>
  </div>
</template>

<script>

import { inputValidationsMixin } from '../mixins';

export default {
  name: 'AccountCreate',

  mixins: [inputValidationsMixin],

  computed: {
    firstName: {
      get() {
        return this.$store.state.accounts.account.first_name;
      },
      set(value) {
        this.$store.commit('accounts/setFirstName', value);
      }
    },
    middleName: {
      get() {
        return this.$store.state.accounts.account.middle_name;
      },
      set(value) {
        this.$store.commit('accounts/setMiddleName', value);
      }
    },
    lastName: {
      get() {
        return this.$store.state.accounts.account.last_name;
      },
      set(value) {
        this.$store.commit('accounts/setLastName', value);
      }
    },
    email: {
      get() {
        return this.$store.state.accounts.account.email;
      },
      set(value) {
        this.$store.commit('accounts/setEmail', value);
      }
    },
    password: {
      get() {
        return this.$store.state.accounts.account.password;
      },
      set(value) {
        this.$store.commit('accounts/setPassword', value);
      }
    },
    phone: {
      get() {
        return this.$store.state.accounts.account.phone;
      },
      set(value) {
        this.$store.commit('accounts/setPhone', value);
      }
    },
    type: {
      get() {
        return this.$store.state.accounts.account.account_type;
      },
      set(value) {
        this.$store.commit('accounts/setAccountType', value);
      }
    },
    self_declared_business_name: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_name;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessName', value);
      }
    },
    self_declared_business_type: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_type;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessType', value);
      }
    },
    self_declared_business_type_other_text: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_type_other_text;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessTypeText', value);
      }
    }
  },

  methods: {
    createAccount() {
      if (this.validateEmail(this.email)) {
        this.$store.commit('accounts/setSignupClient', 'zengarden');
        this.$store.dispatch('accounts/createAccount');
      }
    }
  },

  beforeCreate() {
    // https://012.vuejs.org/guide/best-practices.html#Data_Initialization
    // we must initialize all fields that we bind to (even through computed fields)
    this.$store.commit('accounts/setAccount', {
      account_type: null,
      first_name: null,
      middle_name: null,
      last_name: null,
      self_declared_business_name: null,
      self_declared_business_type: null,
      self_declared_business_type_other_text: null,
      email: null,
      password: null,
      phone: null
    });
  }
};
</script>

<style lang="scss">

</style>
