<template>
  <tr>
    <td><router-link :to="{ name: 'Account', params: { accountUuid: account.account_uuid } }">
      {{account.first_name}} {{ account.last_name }}</router-link></td>
    <td>{{ account.account_email }}</td>
    <td>{{ account.account_phone }}</td>
    <td>{{ account.account_status }}</td>
    <td><a :href="accountSalesforceUrl" target="_blank">Account</a> | <a :href="contactSalesforceUrl" target="_blank">Contact</a></td>
  </tr>
</template>

<script>
import manifest from '../../manifest';

export default {
  name: 'AccountListItem',

  props: ['account'],

  computed: {
    accountSalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.account_sf_id}/view`;
    },
    contactSalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.contact_sf_id}/view`;
    }
  }

};
</script>

<style lang="scss">

</style>
