<template>
  <tr>
    <td>{{ charge.created | moment('MM/DD/YYYY h:mma') }}</td>
    <td>{{ charge.amount / 100 }}</td>
    <td>{{ charge.captured }}</td>
    <td>{{ charge.source_brand }} {{ charge.source_funding }}<br>{{ charge.source_last4 }} {{ charge.source_exp_month }}/{{ charge.source_exp_year }}</td>
    <template v-if="hasPermission('read:charges') || hasPermission('access:developers')">
      <td><template v-if="this.charge.failure_code"><b>{{ getTitleErrorMessage() }}</b><br>{{ getBodyErrorMessage() }}</template></td>
      <td>{{ charge.outcome_type }} <br> {{ charge.outcome_network_status }} <br> {{ charge.outcome_reason }} <br> {{ charge.outcome_seller_message }} <br> risk: {{ charge.outcome_risk_score }} {{ charge.outcome_risk_level }}</td>
      <td><a :href="url" target="_blank">{{ urlLabel }}</a></td>
    </template>
  </tr>
</template>

<script>
import manifest from '../../manifest';
import { hasPermissionMixin } from '@/components/mixins';
import { stripeCodes, zenbusinessMappingCodes, paymentErrorTitle, paymentErrorMessages } from '@/assets/constants/stripeCodes'

export default {
  name: 'ChargeListItem',
  props: ['charge'],
  mixins: [
    hasPermissionMixin
  ],
  computed: {
    url() {
      if(this.charge.charge.startsWith('ch_')) {
        return `${manifest.clients.stripe.host}payments/${this.charge.charge}`;
      }
      return `${manifest.clients.recurly.host}/transactions/${this.charge.charge}`;
    },
    urlLabel() {
      return this.charge.charge.startsWith('ch_') ? "Stripe" : "Recurly";
    }
  },
  methods: {
    getZenBusinessErrorMessage() {
      return this.charge.failure_code?stripeCodes[this.charge.failure_code]:null;
    },
    getTitleErrorMessage() {
      const zbError = this.getZenBusinessErrorMessage()
      if(zbError) {
        const message = zenbusinessMappingCodes[zbError]
        const titleMessage = paymentErrorTitle[message]
        return titleMessage?titleMessage:'Unkown Error';
      }
      return ''
    },
    getBodyErrorMessage() {
      const zbError = this.getZenBusinessErrorMessage()
      if(zbError) {
        const message = zenbusinessMappingCodes[zbError]
        const bodyMessage = paymentErrorMessages[message]
        return bodyMessage?bodyMessage:'Contact Support.';
      }
      return ''
    }
  }
};
</script>

