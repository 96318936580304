<template>
  <Modal @closeModal="$emit('closeModal')">
    <template v-slot:title><h5>Cancel Migration</h5></template>
    <template v-slot:body>
      <p>Are you sure you want to cancel the migration process?</p>
    </template>
    <template v-slot:footer>
      <div class="fullwidth d-flex justify-content-center">
        <ZgButton class="zg-btn-danger mx-3" @click="cancelMigration" :isSubmitting="submitting"><template v-slot:title>Yes</template></ZgButton>
        <ZgButton class="zg-btn-light mx-3" @click="$emit('closeModal')"><template v-slot:title>No</template></ZgButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import ZgButton from "@/components/common/Generics/ZgButton";
import Modal from '@/components/common/Modal';

export default {
  name: 'CustomerAssetsCancelMigrationModal',
  components:{
    ZgButton,
    Modal
  },
  data() {
    return {
      submitting: false
    };
  },
  props: {
    orderTaskUuid: {
      type: String,
      optional: false
    }
  },
  methods: {
    ...mapActions('zbMoney', ['cancelMigrationPreferenceCustomerAssets']),
    async cancelMigration() {
      this.submitting = true;
      await this.cancelMigrationPreferenceCustomerAssets({ orderTaskUuid: this.orderTaskUuid });
      this.submitting = false;
      this.$emit('confirmed');
    }
  }

};
</script>
