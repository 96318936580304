<template>
  <div>
    <vue-headful title="Accounts" />
    <div class="top25">
      <div class="row justify-content-between mb-2">
        <div class="col-auto mb-2">
          <h1>Accounts</h1>
          <router-link id="createAccount" class="nav-link top10" to="/account/create">
            <span class="btn-info btn-sm">Create Account</span>
          </router-link>
        </div>
        <div class="col-auto mb-2">
          <form class="form-inline ms-auto" v-on:submit="searchForAccountByEmail" v-on:submit.prevent v-on:submit.stop>
            <div class="form-group">
              <input class="form-control-sm mx-2" placeholder="Email" type="text" v-model="accountEmail" />
              <input class="btn-info btn-sm" type="submit" value="Search" />
            </div>
          </form>
        </div>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th v-for="(header, i) in columnHeaders" :key="i">
              <a href="" @click.prevent="sortingOptions(header.column)">{{header.header}}</a>
              <span v-if="header.column === sortBy">
                <i :class="[reverse ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                <a href="" class="fa fa-times" @click="defaultSorting()" ></a>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <AccountListItem v-for="account in sortedData" :key="account.account_uuid" :account="account" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortingDataMixin } from '../mixins';
import AccountListItem from './account-list-item';

export default {
  name: 'AccountList',

  mixins: [sortingDataMixin],

  components: {
    AccountListItem
  },
  data() {
    return {
      sortBy: 'default',
      reverse: false,
      columnHeaders: [
        { column: 'first_name|last_name', header: 'Name' },
        { column: 'account_email', header: 'Email' },
        { column: 'account_phone', header: 'Phone' },
        { column: 'account_status', header: 'Status' },
        { column: 'salesforce', header: 'Salesforce' }
      ],
      accountEmail: ''
    };
  },
  computed: {
    ...mapState(['tokenExpired']),
    ...mapState('accounts', ['accounts']),
    sortedData() {
      return this.accounts.concat().sort(this.sorting(this.sortBy, this.reverse));
    }
  },
  methods: {
    searchForAccountByEmail() {
      this.$store.dispatch('accounts/findByEmail', { email: this.accountEmail });
    },
    defaultSorting() {
      this.sortBy = 'default';
      this.reverse = false;
    },
    sortingOptions(sortBy) {
      if (this.sortBy === sortBy) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }

      this.sortBy = sortBy;
    }
  },
  mounted() {
    this.$store.dispatch('accounts/fetchAccounts');
  }
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  #createAccount {
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
  .account-list-header-center {
    text-align: center;
  }
</style>
