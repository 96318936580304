<template>
  <div>
    <h2 class="header">Info</h2>
    <div class="box">
      <div class="row">
        <div class="col-md-4">
          <dt>International</dt>
          <dd>{{ account.international }}</dd>
        </div>
        <div class="col-md-4">
          <dt>Test Account</dt>
          <dd>{{ account.test_account }}</dd>
        </div>
        <div class="col-md-4">
          <dt>Email Opt Out</dt>
          <dd>{{ account.email_opt_out }}</dd>
        </div>
      </div>
    </div>
    <div v-if="account.account_type == 'reseller'">
      <h2 class="header">Reseller Info</h2>
      <div class="box">
        <div class="row">
          <div class="col-md-4">
            <dt>Self-declared Name</dt>
            <dd>{{ account.self_declared_business_name }}</dd>
          </div>
          <div class="col-md-4">
            <dt>Self-declared Type</dt>
            <dd>{{ account.self_declared_business_type }}</dd>
          </div>
          <div v-if="account.self_declared_business_type=='other'" class="col-md-4">
            <dt>Self-declared Other</dt>
            <dd>{{ account.self_declared_business_type_other_text }}</dd>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AccountView',

  computed: mapState('accounts', ['account'])

};
</script>

<style lang="scss">
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
