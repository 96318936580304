<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Obfuscate Account?</h5>
          <button type="button" class="btn-close" @click="$emit('closeModal')" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Check state records to confirm neither ZenBusiness nor RA inc. is/has been the Registered Agent on file in the last two years</p>
        </div>
        <div class="modal-footer">
          <ZgButton class="zg-btn-light" @click="$emit('closeModal')"><template v-slot:title>Cancel</template></ZgButton>
          <ZgButton class="zg-btn-danger" @click="handleObfuscation" :isEnable="isEnabled" :isSubmitting="isSubmitting">
            <template v-slot:title>Proceed with removing customer data</template>
          </ZgButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ZgButton from "@/components/common/Generics/ZgButton";

export default {
  name: 'AccountObfuscationModal',
  components:{
    ZgButton,
  },
  data() {
    return {
      isSubmitting: false,
      isEnabled: true
    };
  },
  props: [],
  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    })
  },
  methods: {
    ...mapActions({
      obfuscateAccount: 'accounts/obfuscateAccount'
    }),
    handleObfuscation() {
      //call to action to call zapi goes here
      this.isEnabled = false
      this.isSubmitting = true
      this.obfuscateAccount({accountUuid: this.accountUuid})
        .finally(() => this.isSubmitting=false)
    }
  }

};
</script>

<style lang="scss" scoped>
.badge {
  text-transform: capitalize;
}

</style>
