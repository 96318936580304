<template>
  <div>
    <vue-headful :title="pageTitle"></vue-headful>
    <ZgAlert v-if="account && account.obfuscated" :alert="obfuscatedAlert"/>
    <AccountDetail />
    <div class="row heading">
      <div class="col-md-3">
        <AccountSideBar></AccountSideBar>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isOrders" @click="handleOrdersClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Orders</template>
                </ZgButton>
              </li>
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isCharges" @click="handleChargesClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Charges</template>
                </ZgButton>
              </li>
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isCustomerAssets" @click="handleCustomerAssetsClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Customer Assets</template>
                </ZgButton>
              </li>
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isBusinessEntities" @click="handleBusinessEntitiesClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Business Entities</template>
                </ZgButton>
              </li>
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isInfo" @click="handleInfoClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Info</template>
                </ZgButton>
              </li>
              <li class="nav-item">
                <ZgButton class="nav-link btn btn-link" v-bind:class="isUsers" @click="handleUsersClick" :disabled="submitted" :isSubmiting="false" type="submit">
                  <template v-slot:title>Users</template>
                </ZgButton>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AccountDetail from './AccountDetail.vue';
import AccountSideBar from './account-side-bar';
import ZgAlert from '../utils/ZgAlert.vue';
import ZgButton from '../common/Generics/ZgButton.vue'
import messages from '../../assets/messages';

export default {
  name: 'Account',
  components: {
    AccountDetail,
    AccountSideBar,
    ZgAlert,
    ZgButton
  },

  props: ['accountUuid'],

  data() {
    return {
      obfuscatedAlert: messages.accountObfuscated.error,
      submitted: false
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.accounts.account,
      orders: (state) => state.orders.orders,
      businessEntities: (state) => state.businessEntities,
      deviceInfo: (state) => state.accounts.deviceInfo
    }),
    currentComponentName() {
      const [ currentComponent ] = this.$route.matched.slice(-1);
      return currentComponent.name
    },
    isOrders() {
      return {
        active: this.currentComponentName === 'AccountOrdersView' || this.currentComponentName === 'Account'
      };
    },
    isCharges() {
      return {
        active: this.currentComponentName === 'ChargesView'
      };
    },
    isBusinessEntities() {
      return {
        active: this.currentComponentName === 'AccountBusinessEntitiesView'
      };
    },
    isCustomerAssets () {
      return {
        active: this.currentComponentName === 'CustomerAssetsView'
      }
    },
    isUsers() {
      return {
        active: this.currentComponentName === 'UsersInfoView'
      };
    },
    isInfo() {
      return {
        active: this.currentComponentName === 'AccountInfoView'
      };
    },
    pageTitle() {
      return `Account - ${this.account.account_name || this.account.account_uuid}`;
    }
  },

  methods: {
    ...mapActions({
      fetchAccount: 'accounts/fetchAccount',
      fetchDeviceInfo: 'accountDeviceInfo/fetchDeviceInfo',
      fetchOrdersByAccount: 'orders/fetchOrdersByAccount',
      fetchEntitiesByAccount: 'businessEntities/fetchEntitiesByAccount',
      fetchOrderTasksByAccount: 'orders/fetchOrderTasksByAccount',
      fetchDocumentsByAccount: 'businessEntities/fetchDocumentsByAccount',
      fetchUsers: 'accounts/fetchUsers',
      fetchCharges: 'accounts/fetchCharges'
    }),
    handleOrdersClick() {
      if (this.$router.history.current.name !== 'AccountOrdersView') {
        this.$router.push({ name: 'AccountOrdersView', params: { uuid: this.accountUuid } });
      }
    },
    handleBusinessEntitiesClick() {
      if (this.$router.history.current.name !== 'AccountBusinessEntitiesView') {
        this.$router.push({ name: 'AccountBusinessEntitiesView', params: { uuid: this.accountUuid } });
      }
    },
    handleChargesClick() {
      if (this.$router.history.current.name !== 'ChargesView') {
        this.$router.push({ name: 'ChargesView', params: { uuid: this.accountUuid } });
      }
    },
    handleCustomerAssetsClick() {
      if (this.$router.history.current.name !== 'CustomerAssetsView') {
        this.$router.push({ name: 'CustomerAssetsView', params: { uuid: this.uuid } });
      }
    },
    handleInfoClick() {
      if (this.$router.history.current.name !== 'AccountInfoView') {
        this.$router.push({ name: 'AccountInfoView', params: { uuid: this.accountUuid } });
      }
    },
    handleUsersClick() {
      if (this.$router.history.current.name !== 'UsersInfoView') {
        this.$router.push({ name: 'UsersInfoView', params: { account_uuid: this.accountUuid } });
      }
    }
  },

  mounted() {
    this.fetchAccount({ accountUuid: this.accountUuid });
    this.fetchDeviceInfo({ accountUuid: this.accountUuid });
    this.fetchOrdersByAccount({ accountUuid: this.accountUuid });
    this.fetchEntitiesByAccount({ accountUuid: this.accountUuid });
    this.fetchOrderTasksByAccount({ accountUuid: this.accountUuid });
    this.fetchDocumentsByAccount(this.accountUuid);
    this.fetchUsers({ accountUuid: this.accountUuid });
    this.fetchCharges({ accountUuid: this.accountUuid });
  }
};
</script>

<style lang="scss" scoped>

</style>
