<template>
  <Modal @closeModal="$emit('closeModal')">
    <template v-slot:title>{{ labelsComponent.titleModal }}</template>
    <template v-slot:body>
      <div class="alert" :class="loadingInformationForModal.classname" role="alert" v-if="!canProceed">
        {{ loadingInformationForModal.message }}
      </div>
      <form class="p-3">
        <div class="form-group">
          <label for="migrationOptionForm">Customer Preference</label>
          <select class="form-control" id="migrationOptionForm"
            v-model="migrationPreference" @change="checkStateApplyCoupon(showCouponInformationMigration)"
          >
            <option key="null" value="null" disabled selected>Please select one</option>
            <option v-for="option in migrationOptions" :key="option.affiliate_pricing_uuid" :value="option.affiliate_pricing_uuid" >{{ option.display_option }}</option>
          </select>
        </div>
        <div class="form-check form-check-inline" v-if="showCouponInformationMigration">          
          <input class="form-check-input" id="couponCheckboxMigration" type="checkbox" v-model="applyCouponMigration" />
          <label class="form-check-label" for="couponCheckboxMigration">Apply Coupon for 20% off.</label>
        </div>
        <div class="form-group">
          <label for="customerReason">Customer Reason</label>
          <select class="form-control" id="customerReason"
            v-model="reasonMigration"
          >
            <option key="null" value="null" disabled selected>Please select one</option>
            <option v-for="reason in getRefundReasons" :key="reason.refund_cancel_reason_definition_name" :value="reason.refund_cancel_reason_definition_name" >{{ `${reason.primary_reason} - ${reason.secondary_reason}` }}</option>
          </select>
        </div>
        <div class="form-group" v-show="showInputForOtherReason">
          <label for="customerOtherReason">More Description</label>
          <input class="form-control" id="customerOtherReason" v-model="otherReasonMigration" />
        </div>
        <ZgButton class="zg-btn-default zg-text-md mt-2" @click="saveChanges" type="button"
          :isSubmitting="isWaitingResponse"
          :isEnable="isCompleteMigration"
        >
          <template v-slot:title><i class="fa fa-save"></i>&nbsp;{{ labelsComponent.titleButton }}</template>
        </ZgButton>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import ZgButton from '@/components/common/Generics/ZgButton';
import { userInformationMixin } from '@/components/mixins';

export default {
  name: 'CustomerAssetsMigrationModal',
  components: { Modal, ZgButton },
  props: ['orderTaskUUID'],
  mixins: [ userInformationMixin ],
  data(){
    return {
      isWaitingResponse: false,
      migrationPreference: null,
      applyCouponMigration: false,
      reasonMigration: null,
      otherReasonMigration: '',
    }
  },
  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid',
      getRefundReasons: 'orders/getRefundReasons',
      getOrderTask: 'orders/getOrderTask',
      getMigrationOptionsCustomerAssets: 'zbMoney/getMigrationOptionsCustomerAssets',
      getCoupons: 'products/getCoupons',
    }),
    getMigrationPreferenceCustomerAssets() {
      if (!this.getOrderTask?.migration_data) return null;
      return {
        migration_preference: this.getOrderTask.migration_data?.affiliate_pricing?.uuid,
        coupon: this.getOrderTask.migration_data?.coupon,
        refund_cancel_reason_definition_uuid: this.getOrderTask.cancel_reason_definition,
        migration_reason_other: this.getOrderTask.cancel_reason_other,
      }
    },
    isMigrationDifferent() {
      const validations = []
      if(this.getMigrationPreferenceCustomerAssets) {
        validations.push(this.migrationPreference != this.getMigrationPreferenceCustomerAssets.migration_preference);
        validations.push(this.applyCouponMigration != (this.getMigrationPreferenceCustomerAssets.coupon));
        validations.push(this.reasonMigration != this.getMigrationPreferenceCustomerAssets.refund_cancel_reason_definition_uuid);
        validations.push(this.otherReasonMigration != this.getMigrationPreferenceCustomerAssets.migration_reason_other);
        return validations.some(e => e);
      }
      return true;
    },
    loadingInformationForModal() {
      if (!(Object.keys(this.migrationOptions).length))
        return {
          "message": "Loading product services.",
          "classname": "alert-info" 
        };
      if (this.migrationOptions.length === 0)
        return {
          "message": "There aren't possible products for the migration.",
          "classname": "alert-danger"
      } 
      return {
          "message": "Options Loaded.",
          "classname": "alert-success" 
        };
    },
    canProceed() {
      return (this.migrationOptions==undefined || this.migrationOptions.length==0)?false:true;
    },
    isCompleteMigration() {
      if (this.isMigrationDifferent && this.migrationPreference && this.reasonMigration) {
        if (this.showInputForOtherReason) {
          if (this.otherReasonMigration.length>3) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
      return false;
    },
    labelsComponent() {
      const description = {
        titleModal: "Migrate Legacy Order",
        titleButton: "Save"
      }
      if (this.getMigrationPreferenceCustomerAssets) {
        description.titleModal = "Update Legacy Order";
        description.titleButton = "Update";
      }
      return description;
    },
    migrationOptions() {
      const servicesToMigrate = this.getMigrationOptionsCustomerAssets;
      return servicesToMigrate;
    },
    informationMigration() {
      const information = this.migrationOptions.filter(obj => { return obj.affiliate_pricing_uuid === this.migrationPreference });
      return information.length > 0 ? information[0] : null;
    },
    couponInformationMigration() {
      return this.getCoupons.find((c) => c.external_coupon_id === '60_off_first_year');
    },
    showInputForOtherReason() {
      const reason = this.getRefundReasons.filter(obj => { return obj.refund_cancel_reason_definition_name == this.reasonMigration });
      if (reason.length>0 && reason[0]?.secondary_reason == 'Other') {
        return true;
      }
      return false;
    },
    showCouponInformationMigration() {
      const completeInformation = this.informationMigration;
      if (completeInformation) {
        const coupon = this.couponInformationMigration;
        const completeName = `${completeInformation.service_product_name} ${completeInformation.term}`
        if (coupon && completeName == 'zb_money_pro annual') {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      createMigrationPreferenceCustomerAssets: 'zbMoney/createMigrationPreferenceCustomerAssets',
      updateMigrationPreferenceCustomerAssets: 'zbMoney/updateMigrationPreferenceCustomerAssets',
      sendMigrationInformationOrderTask: 'zbMoney/sendMigrationInformationOrderTask',
      fetchOrderTask: 'orders/fetchOrderTaskV2',
      fetchMigrationServicesOptions: 'zbMoney/fetchMigrationServicesOptions',
      fetchCoupons: 'products/fetchCouponsV2',
      clearOrderTask: 'orders/clearOrderTask',
    }),
    saveChanges() {
      this.isWaitingResponse = true;      
      const payload = {
        // Hardcoded values for ZG context.
        'new_affiliate_plan_option_uuid': null,
        'subscription_start': 'at_term_ends',
        'refund_difference': false,
        // Dynamic values
        'new_affiliate_pricing_uuid': this.migrationPreference,
        'coupon_uuid': (this.showCouponInformationMigration && this.applyCouponMigration)?this.couponInformationMigration.coupon_uuid:null,
        'migration_reason': this.reasonMigration,
        'migration_reason_other': this.showInputForOtherReason?this.otherReasonMigration:''
      }
      this.sendMigrationInformationOrderTask({ 
        orderTaskUuid: this.orderTaskUUID,
        payload
      }).finally(() => {
        this.fetchOrderTask({ orderTaskUuid: this.orderTaskUUID });
        this.isWaitingResponse = false;
        this.$emit('closeModal');
      });
    },
    checkStateApplyCoupon(isApplicable) {
      if(isApplicable) {
        this.applyCouponMigration = this.getMigrationPreferenceCustomerAssets?.coupon?true:false;
      }
    },
  },
  created() {
    this.clearOrderTask();
    if(this.orderTaskUUID) {
      this.fetchOrderTask({ "orderTaskUuid": this.orderTaskUUID }).then((response) => {
        if (response?.successful) {
          const orderTask = response.data;
          const migrationData = orderTask?.migration_data;
          if (migrationData) {
            this.migrationPreference = migrationData.affiliate_pricing.uuid;
            this.applyCouponMigration = migrationData.coupon?true:false;
            this.reasonMigration = orderTask.cancel_reason_definition;
            this.otherReasonMigration = orderTask.cancel_reason_other;
          }
        }
      });
      this.fetchMigrationServicesOptions({ "orderTaskUuid": this.orderTaskUUID });
      this.fetchCoupons({
        fields: ['external_coupon_id', 'coupon_uuid'],
        getAll: true
      });
    }
  },
  updated() {
    if(this.orderTaskUUID) {
      this.fetchMigrationDataOrderTask({ "orderTaskUuid": this.orderTaskUUID });
      this.fetchMigrationServicesOptions({ "orderTaskUuid": this.orderTaskUUID });
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.modal {
  &-dialog {
    &--full-width {
      @include mq($from: xs) {
        max-width: 96vw !important;
      }
    }
  }
  &-body {
    overflow-y: auto;
    max-height: 70vh;
  }
  &-header, &-footer {
    background: rgba($stone-5, .25);
  }
  form {
    display: flex;
    flex-direction: column;
    row-gap: em(20);
    .form-group {
      display: flex;
      flex-direction: column;
      row-gap: em(10);
      label {
        font-weight: bold;
      }
    } 
  }
}
</style>
