<template>
  <div>
      <div class="box">
        The last 10 charge attempts from stripe in reverse date order regardless of a successful order.
        <br><br>
        <div class="row">
          <div class="col">
            <table class="table table-sm table-borderless">
              <thead>
              <tr>
                <th>Created</th>
                <th>Amount</th>
                <th>Successfully Charged</th>
                <th>Card</th>
                <template v-if="hasPermission('read:charges') || hasPermission('access:developers')">
                  <th>Failure</th>
                  <th>Network Outcome</th>
                  <th>Link</th>
                </template>
              </tr>
              </thead>
              <tbody>
                <ChargeListItem v-for="charge in charges" :key="charge.charge" v-bind:charge="charge" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import ChargeListItem from './ChargeListItem'
import { hasPermissionMixin } from '@/components/mixins';

export default {
  name: 'ChargesView',

  components: {
    ChargeListItem
  },
  mixins: [
    hasPermissionMixin
  ],
  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),
    ...mapState({
      charges: (state) => state.accounts.charges
    })
  },

  methods: {
    ...mapActions({
      fetchCharges: 'accounts/fetchCharges',
    }),
  }
};
</script>
