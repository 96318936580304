<template>
  <tr>
    <td>
      <SlotCopy :valueToCopy="businessEntity.business_entity_name">
        <router-link :to="{ name: 'BusinessEntity', params: { businessEntityUuid: businessEntity.business_entity_uuid } }">
          {{businessEntity.business_entity_name }}
        </router-link>
      </SlotCopy>
    </td>
    <td>
      <SlotCopy :valueToCopy="businessEntityJurisdiction">
        {{ businessEntityJurisdiction }}
      </SlotCopy>
    </td>
    <td>
      <SlotCopy :valueToCopy="businessEntity.business_entity_type">
        {{ businessEntity.business_entity_type }}
      </SlotCopy>
    </td>
    <td>{{ businessEntity.active_flag }}</td>
    <td><a :href="businessEntitySalesforceUrl" target="_blank">Business Entity</a></td>
    <td>
      <span class="badge bg-info" v-if="!businessEntity.has_ra && !businessEntity.has_wfg">None</span>
      <span class="badge bg-info" v-if="businessEntity.has_ra">Registered Agent</span>
      <span class="badge bg-info" v-if="businessEntity.has_wfg">Worry Free Guarantee</span>
    </td>
  </tr>
</template>

<script>
import manifest from '../../manifest';
import SlotCopy from '@/components/v2/common/Generics/SlotCopy';

export default {
  name: 'BusinessEntityListItem',
  props: ['businessEntity'],
  components: {
      SlotCopy
    },
  computed: {
    businessEntitySalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.businessEntity.business_entity_sf_id}/view`;
    },
    businessEntityJurisdiction() {
      return this.businessEntity.business_entity_data.domestic_jurisdiction;
    }
  }
};
</script>

<style lang="scss">
.table-sm td, .table-sm th {
    padding: 0.3rem;
}
.table>:not(:first-child) {
    border-top: 1px solid #dee2e6 !important;
}
</style>
