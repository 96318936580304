// Codes from https://github.com/zenbusiness/zenbusiness.com/blob/main/src/client/utils/PaymentUtils.js
export const stripeCodes = {
  balance_insufficient: 1003,
  call_issuer: 1001,
  card_declined: 1001,
  card_not_supported: 1007,
  card_velocity_exceeded: 1003,
  do_not_honor: 1001,
  do_not_try_again: 1001,
  expired_card: 1005,
  fraudulent: 1001,
  generic_decline: 1001,
  incorrect_cvc: 1008,
  incorrect_number: 1009,
  incorrect_zip: 1010,
  insufficient_funds: 1003,
  invalid_amount: 1015,
  invalid_card_type: 1016,
  invalid_cvc: 1011,
  invalid_expiry_month: 1012,
  invalid_expiry_year: 1012,
  invalid_number: 1013,
  issuer_not_available: 1002,
  lost_card: 1001,
  merchant_blacklist: 1001,
  new_account_information_available: 1017,
  no_action_taken: 1001,
  not_permitted: 1001,
  offline_pin_required: 1004,
  online_or_offline_pin_required: 1004,
  pickup_card: 1001,
  postal_code_invalid: 1014,
  processing_error: 1018,
  reenter_transaction: 1002,
  restricted_card: 1001,
  revocation_of_all_authorizations: 1001,
  revocation_of_authorization: 1001,
  security_violation: 1001,
  service_not_allowed: 1001,
  stolen_card: 1001,
  stop_payment_order: 1001,
  testmode_decline: 1001,
  transaction_not_allowed: 1001,
  try_again_later: 1002,
  withdrawal_count_limit_exceeded: 1006
}

export const zenbusinessMappingCodes = {
  1018: 'processingError',
  1020: 'paymentError',
  1021: 'tokenError',
  1019: 'matchingName',
  1001: 'genericStripe',
  1002: 'tryAgainStripe',
  1003: 'insufficientFunds',
  1004: 'debitCard',
  1006: 'limitExceeded',
  1007: 'notSupported',
  1009: 'incorrectNumber',
  1013: 'invalidNumber',
  1015: 'invalidAmount',
  1016: 'invalidCardType',
  1017: 'invalidCard',
  1008: 'incorrectCVC',
  1011: 'invalidCVC',
  1005: 'expiredCard',
  1012: 'incorrectDate',
  1010: 'incorrectZip',
  1014: 'invalidZip'
}

export const paymentErrorTitle = {
  badRequest: 'Please contact support',
  cardNumberInvalid: 'Please fix your data',
  cvcInvalid: 'Please fix your data',
  dataIntegrity: 'Please contact support',
  dataMissingError: 'Please fix your data',
  debitCard: 'Please fix your data',
  duplicateEmail: 'Please fix your data',
  emailExists: 'Please sign in',
  emailInvalid: 'Please fix the data',
  emailUndeliverable: 'Please fix the data',
  expDateInvalid: 'Please fix your data',
  expDateFutureInvalid: 'Please fix your data',
  expirationInvalid: 'Please fix your data',
  expiredCard: 'Please fix your data',
  genericStripe: 'Please contact bank',
  incorrectCVC: 'Please fix your data',
  incorrectDate: 'Please fix your data',
  incorrectNumber: 'Please fix your data',
  incorrectZip: 'Please fix your data',
  insufficientFunds: 'Please contact bank',
  invalidAmount: 'Please contact bank',
  invalidCard: 'Please contact bank',
  invalidCardType: 'Please contact bank',
  invalidCVC: 'Please fix your data',
  invalidNumber: 'Please fix your data',
  invalidZip: 'Please fix your data',
  limitExceeded: 'Please fix your data',
  matchingName: 'Please fix your data',
  notFound: 'Please contact support',
  notSupported: 'Please contact bank',
  passwordInvalid: 'Please fix your data',
  paymentError: 'Please fix your data',
  processingError: 'Please contact support',
  serverError: 'Please contact support',
  tokenError: 'Please contact support',
  tryAgainStripe: 'Please try again',
  unauthorized: 'Please contact support'
};

export const paymentErrorMessages = {
  badRequest: 'We were unable to create your order. Please contact support at (800) 480-6977 or by message below for assistance. (400)',
  cardNumberInvalid: 'Your credit card number doesn’t look quite right. We need a valid credit card number.',
  cvcInvalid: 'Your CVC number doesn’t look quite right. We need a valid CVC number',
  dataIntegrity: 'We were unable to create your order. Please contact support at (800) 480-6977 or by message below for assistance. (409)',
  dataMissingError: 'We are missing some required data for your order. Click the button to return to the information confirmation page to enter the necessary data.',
  debitCard: 'We don’t support debit cards at this time. Please try a different card.',
  duplicateEmail: 'An account for this email address has already been created. Go back to reenter a new email or log in with the account.',
  emailExists: 'This email currently exists in the system. Please sign in before continuing.',
  emailInvalid: 'The email address doesn’t look quite right. We need a valid email address.',
  emailUndeliverable: 'This address doesn’t look like it can receive email. We need a valid email address.',
  expDateInvalid: 'Your expiration date doesn’t look quite right. We need an expiration date formatted as MM/YYYY.',
  expDateTwoDigitsYearInvalid: 'Your expiration date doesn’t look quite right. We need an expiration date formatted as MM/YY.',
  expDateFutureInvalid: 'Your expiration date is in the past.',
  expirationInvalid: 'You expiration date is not valid.',
  expiredCard: 'Your credit card is expired. Please contact your card issuer for more information or use a different card.',
  genericStripe: 'Your card issuer has declined this transaction. Please contact your card issuer for more information or try again with a different card.',
  incorrectCVC: 'The CVC number is not correct for this card. Please check the number, or try a different card.',
  incorrectDate: 'The expiration date is not correct for this card. Please check the expiration date, or try a different card.',
  incorrectNumber: 'Your credit card number appears to be incorrect. Please check the number, or try a different card.',
  incorrectZip: 'Your zip code appears to be incorrect for this card. Please check the zip code, or try a different card.',
  insufficientFunds: 'Your card issuer has indicated that this card does not have sufficient funds for this order. Please contact your card issuer for more information or use a different card.',
  invalidAmount: 'Your card issuer is not allowing a charge for this amount. Please contact your card issuer for more information, or you may try a different card.',
  invalidCard: 'Your card issuer indicates that this card cannot be used. Please contact your card issuer for more information, or you may try a different card.',
  invalidCardType: 'Your card issuer is not allowing this card to be used. Please contact your card issuer for more information, or you may try a different card.',
  invalidCVC: 'Your CVC number doesn’t look quite right. We need a valid CVC number.',
  invalidNumber: 'Your credit card number doesn’t look quite right. We need a valid credit card number.',
  invalidZip: 'Your Zip code doesn’t look quite right. We need a valid Zip code.',
  limitExceeded: 'This credit card has been declined too many times. You can try this card again in 24 hours, or you may try a different card.',
  matchingName: 'You have already created an entity with this name. Back up and change the name.',
  notFound: 'We were unable to create your order. Please contact support at (800) 480-6977 or by message below for assistance. (404)',
  notSupported: 'This credit card can not be used for this purchase. Please contact your card issuer, or you may try a different card.',
  passwordInvalid: 'Your password must contain at least 8 characters.',
  paymentError: 'Your payment failed. Please try a different card or contact support at (800) 480-6977 for assistance. (402)',
  processingError: 'We were unable to process your credit card at this time. Please try again or, if the problem persists, contact support at (800) 480-6977 or by message below for assistance.',
  serverError: 'We were unable to create your order. Please contact support at (800) 480-6977 or by message below for assistance. (500)',
  tokenError: 'We were unable to process your credit card at this time. Please try again or, if the problem persists, contact support at (800) 480-6977 or by message below for assistance. (1021)',
  tryAgainStripe: 'Your card issuer was unable to process this transaction, but requests that you should try again. If the problem persist, Please contact your card issuer for more information or use a different card.',
  unauthorized: 'We were unable to create your order. Please contact support at (800) 480-6977 or by message below for assistance. (401)'
};
