<template>
  <div class="modal" >
    <div class="modal-dialog  modal-xl modal-dialog-centered">
      <div class="modal-content" style="max-height: 70vh;">
        <div class="modal-header">
          <h5 class="modal-title">Auth Logs for user: {{ logData.username }}</h5>
          <button type="button" @click="close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="overflow: scroll;">
          <div v-if="!showLogDetails">
            <table class="table table-lg table-borderless">
             <tr>
              <th>Event Type</th>
              <th>Time</th>
              <th>Application</th>
              <th>IP</th>
              <th></th>
             </tr>
             <tr v-for="log in logData.logs" v-bind:key="log.logId"> 
                <td>
                    {{ getEventName(log.type) }}
                </td>
                <td>
                   {{ formatDate(log.timestamp) }}
                </td>
                <td>
                   {{ log.clientName }}
                </td>
                <td>
                   {{ log.ip }}
                </td>
                <td>
                  <div class="btn btn-sm btn-outline-primary" style="width:max-content"
                    @click="handleViewFullLog(log)">View Details</div>
                </td>
             </tr>
            </table>
          </div>
          <div v-if="showLogDetails" >
            <h5>Full log content:</h5>
            <div>
              <pre>{{fullLogFormatted}}</pre>
            </div>
        </div>
        </div>
        <div class="modal-footer">
          <div v-if="!showLogDetails" style="display: flex; align-items: center;">
            <div class="btn btn-sm btn-outline-primary mx-2" v-if="currentPage!==0" @click="handlePrevPage">
              Prev Page
            </div>
            <div class="btn btn-sm btn-outline-primary mx-2" v-if="logData.next" @click="handleNextPage">
              Next Page
            </div>
          </div>
          <div v-if="showLogDetails" class="btn btn-sm btn-outline-primary"
              @click="handleReturnToList">Return to Log List</div>
          </div>  
      </div>
    </div>
  </div>
</template>
<script>
const EVENT_NAMES={
  "admin_update_launch":"Auth0 Update Launched",
  "api_limit":"Rate Limit on the Authentication or Management APIs",
  "cls":"Code/Link Sent",
  "cs":"Code Sent",
  "depnote":"Deprecation Notice",
  "du":"Deleted User",
  "f":"Failed Login",
  "fapi":"Operation on API failed",
  "fc":"Failed by Connector",
  "fce":"Failed Change Email",
  "fco":"Failed by CORS",
  "fcoa":"Failed cross-origin authentication",
  "fcp":"Failed Change Password",
  "fcph":"Failed Post Change Password Hook",
  "fcpn":"Failed Change Phone Number",
  "fcpr":"Failed Change Password Request",
  "fcpro":"Failed Connector Provisioning",
  "fcu":"Failed Change Username",
  "fd":"Failed Delegation",
  "fdeac":"Failed Device Activation",
  "fdeaz":"Failed Device Authorization Request",
  "fdecc":"User Canceled Device Confirmation",
  "fdu":"Failed User Deletion",
  "feacft":"Failed Exchange",
  "feccft":"Failed Exchange",
  "fede":"Failed Exchange",
  "fens":"Failed Exchange",
  "feoobft":"Failed Exchange",
  "feotpft":"Failed Exchange",
  "fepft":"Failed Exchange",
  "fepotpft":"Failed Exchange",
  "fercft":"Failed Exchange",
  "fertft":"Failed Exchange",
  "ferrt":"Failed Exchange",
  "fi":"Failed invite accept",
  "flo":"Failed Logout",
  "fn":"Failed Sending Notification",
  "fp":"Failed Login (Incorrect Password)",
  "fs":"Failed Signup",
  "fsa":"Failed Silent Auth",
  "fu":"Failed Login (Invalid Email/Username)",
  "fui":"Failed users import",
  "fv":"Failed Verification Email",
  "fvr":"Failed Verification Email Request",
  "gd_auth_failed":"MFA Auth failed",
  "gd_auth_rejected":"MFA Auth rejected",
  "gd_auth_succeed":"MFA Auth success",
  "gd_enrollment_complete":"MFA enrollment complete",
  "gd_otp_rate_limit_exceed":"Too many failures",
  "gd_recovery_failed":"Recovery failed",
  "gd_recovery_rate_limit_exceed":"Too many failures",
  "gd_recovery_succeed":"Recovery success",
  "gd_send_email":"Email Sent",
  "gd_send_pn":"Push notification sent",
  "gd_send_pn_failure":"Push notification sent",
  "gd_send_sms":"SMS sent",
  "gd_send_sms_failure":"SMS sent failures",
  "gd_send_voice":"Voice call made",
  "gd_send_voice_failure":"Voice call failure",
  "gd_start_auth":"Second factor started",
  "gd_start_enroll":"Enroll started",
  "gd_start_enroll_failed":"Enrollment failed",
  "gd_tenant_update":"Guardian tenant update",
  "gd_unenroll":"Unenroll device account",
  "gd_update_device_account":"Update device account",
  "gd_webauthn_challenge_failed":"Enrollment challenge issued",
  "gd_webauthn_enrollment_failed":"Enroll failed",
  "limit_delegation":"Too Many Calls to /delegation",
  "limit_mu":"Blocked IP Address",
  "limit_wc":"Blocked Account",
  "limit_sul":"Blocked Account",
  "mfar":"MFA Required",
  "mgmt_api_read":"Management API read Operation",
  "pla":"Pre-login assessment",
  "pwd_leak":"Breached password",
  "resource_cleanup":"Refresh token excess warning",
  "s":"Success Login",
  "sapi":"Success API Operation",
  "sce":"Success Change Email",
  "scoa":"Success cross-origin authentication",
  "scp":"Success Change Password",
  "scpn":"Success Change Phone Number",
  "scpr":"Success Change Password Request",
  "scu":"Success Change Username",
  "sd":"Success Delegation",
  "sdu":"Success User Deletion",
  "seacft":"Success Exchange",
  "seccft":"Success Exchange",
  "sede":"Success Exchange",
  "sens":"Success Exchange",
  "seoobft":"Success Exchange",
  "seotpft":"Success Exchange",
  "sepft":"Success Exchange",
  "sercft":"Success Exchange",
  "sertft":"Success Exchange",
  "si":"Successful invite accept",
  "signup_pwd_leak":"Breached password",
  "srrt":"Success Revocation",
  "slo":"Success Logout",
  "ss":"Success Signup",
  "ssa":"Success Silent Auth",
  "sui":"Success users import",
  "sv":"Success Verification Email",
  "svr":"Success Verification Email Request",
  "sys_os_update_end":"Auth0 OS Update Ended",
  "sys_os_update_start":"Auth0 OS Update Started",
  "sys_update_end":"Auth0 Update Ended",
  "sys_update_start":"Auth0 Update Started",
  "ublkdu":"User login block released",
  "w":"Warnings During Login"
}

export default {
    name: 'UserLogModal',

    props: ['logData'],

    data() {
      return {
        showLogDetails: false,
        selectedLog: null,
        currentPage: 0
      }
    },

    computed:{
      fullLogFormatted(){
        if(this.selectedLog){
          return JSON.stringify(JSON.parse(this.selectedLog.fullLog),null,2)
        }
        return ""
      },

    },

    methods:{
      handleViewFullLog(log){
        this.selectedLog=log;
        this.showLogDetails = true;
      },
      handleReturnToList(){
        this.selectedLog=null
        this.showLogDetails = false
      },
      handleNextPage(){
        this.currentPage+=1;
        this.$store.dispatch('accounts/fetchAuth0UserLogs',{
          uuid:this.logData.userUuid,
          page:this.currentPage,
        })
      },
      handlePrevPage(){
        this.currentPage-=1;
        this.$store.dispatch('accounts/fetchAuth0UserLogs',{
          uuid:this.logData.userUuid,
          page:this.currentPage,
        })
      },
      getEventName(type){
        return EVENT_NAMES[type] ? EVENT_NAMES[type] : type;
      },
      formatDate(dateString){
        let date=new Date(dateString)
        if(!date){
          return ""
        }
        let formatedDate = date.toLocaleString('en-US');
        return formatedDate;
      },
      close(){
        this.$emit("closeUserLogs");
      }
    }
}
</script>
