<template>
  <div>
    <h2 class="header">Business Entities</h2>
    <div class="box">
      <div class="row">
        <div class="col">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th v-for="(header, i) in columnHeaders" :key="i">
                  <a href="" @click.prevent="sortingOptions(header.column)">{{header.header}}</a>
                  <span v-if="header.column === sortBy">
                    <i :class="[reverse ? 'fas fa-arrow-down' : 'fas fa-arrow-up' ]"></i>
                    <a href="" class="fa fa-times" @click.prevent="defaultSorting()" ></a>
                  </span>
                </th>
                <th>Active</th>
                <th>Salesforce</th>
                <th>Active Recurring Services</th>
              </tr>
            </thead>
            <tbody>
              <BusinessEntityListItem v-for="businessEntity in sortedData" v-bind:key="businessEntity.business_entity_uuid" :businessEntity="businessEntity"></BusinessEntityListItem>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sortingDataMixin } from '../mixins';
import BusinessEntityListItem from '../business-entities/business-entity-list-item';

export default {
  name: 'AccountBusinessEntitiesView',

  mixins: [sortingDataMixin],

  components: {
    BusinessEntityListItem
  },

  computed: {
    ...mapState('businessEntities', ['businessEntities']),
    sortedData() {
      return this.businessEntities.concat().sort(this.sorting(this.sortBy, this.reverse));
    },
  },

  data() {
    return {
      sortBy: 'default',
      reverse: false,
      columnHeaders: [
        { column: 'business_entity_name', header: 'Entity Name' },
        { column: 'business_entity_data.domestic_jurisdiction', header: 'Jurisdiction' },
        { column: 'business_entity_type', header: 'Type' },
      ]
    }
  },

  methods: {
    defaultSorting() {
      this.sortBy = 'default';
      this.reverse = false;
    },
    sortingOptions(sortBy) {
      if (this.sortBy === sortBy) {
        this.reverse = !this.reverse;
      } else {
        this.reverse = false;
      }

      this.sortBy = sortBy;
    }
  }
};

</script>

<style scoped>

</style>
