<template>
  <div>
    <UserLogModal v-if="displayModal" v-bind:logData="userLogs" @closeUserLogs="closeUserLogs"/>
    <h2 class="header">Users</h2>
    <UserListItem v-for="user in users" v-bind:key="user.user_uuid" v-bind:user="user"
      @userMfaChanged="reload" @showUserLogs="showUserLogs" />
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import UserListItem from './user-list-item';
import UserLogModal from './user-log-modal';

export default {
  name: 'UsersView',

  components: {
    UserListItem,
    UserLogModal
  },

  data() {
    return {
      userLogModalOpen : false,
    }
  },

  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),
    ...mapState({
      users: (state) => state.accounts.users,
      userLogs: (state) => state.accounts.auth0UserLogs
    }),
    displayModal(){
      return this.userLogs && this.userLogs.logs && this.userLogModalOpen;
    }
  },

  methods: {
    ...mapActions({
      fetchUsers: 'accounts/fetchUsers',
    }),
    reload() {
      this.fetchUsers({ accountUuid: this.accountUuid });
    },
    showUserLogs(){
      this.userLogModalOpen=true;
    },

    closeUserLogs(){
      this.userLogModalOpen=false;
    }
  },

};
</script>

<style lang="scss">
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
