<template>
  <div>
    <div class="row">
      <div class="col-md-2">
        <div id="account-menu" class="menu">
          <h3>Actions</h3>
          <div class="btn btn-info" @click="handleSaveClick">Save</div>
          <div class="btn btn-dark" @click="handleCancelClick">Cancel</div>
        </div>
      </div>
      <div class="col-md-10">
        <form>
          <h2 class="header">Account Info</h2>
          <div class="box">
            <div class="row">
              <div class="col-md-4">
                <dt>First Name</dt>
                <dd><input class="form-control" v-model="firstName"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Middle Name</dt>
                <dd><input class="form-control" v-model="middleName"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Last Name</dt>
                <dd><input class="form-control" v-model="lastName"/></dd>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <dt v-bind:class="{'text-danger': !validateEmail(email)}">Email</dt>
                <dd><input class="form-control" v-model="email"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Phone</dt>
                <dd><input class="form-control" v-model="phone"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Suffix</dt>
                <select class="form-control" v-model="suffix">
                  <option disabled>Select a suffix</option>
                  <option value="">None</option>
                  <option>DDS</option>
                  <option>MD</option>
                  <option>PhD</option>
                  <option>Jr.</option>
                  <option>Sr.</option>
                  <option>I</option>
                  <option>II</option>
                  <option>III</option>
                  <option>IV</option>
                  <option>V</option>
                  <option>VI</option>
                </select>
              </div>
            </div>

          </div>
          <h2 v-if="type===!'reseller'" class="header muted">Reseller Info</h2>
          <div v-if="type===!'reseller'" class="box">
            <div class="row">
              <div class="col-md-4">
                <dt>Self-declared Name</dt>
                <dd><input class="form-control" v-model="self_declared_business_name"/></dd>
              </div>
              <div class="col-md-4">
                <dt>Self-declared Type</dt>
                <dd>
                  <select class="form-control" v-model="self_declared_business_type">
                    <option disabled value="">Please select one</option>
                    <option>lawyer</option>
                    <option>accountant</option>
                    <option>other</option>
                  </select>
                </dd>
              </div>
              <div class="col-md-4" v-if="self_declared_business_type=='other'">
                <dt>Self-declared Other</dt>
                <dd><input class="form-control" v-model="self_declared_business_type_other_text"/></dd>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { inputValidationsMixin } from '../mixins';

export default {
  name: 'AccountEdit',

  mixins: [inputValidationsMixin],

  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),
    firstName: {
      get() {
        return this.$store.state.accounts.account.first_name;
      },
      set(value) {
        this.$store.commit('accounts/setFirstName', value);
      }
    },
    middleName: {
      get() {
        return this.$store.state.accounts.account.middle_name;
      },
      set(value) {
        this.$store.commit('accounts/setMiddleName', value);
      }
    },
    lastName: {
      get() {
        return this.$store.state.accounts.account.last_name;
      },
      set(value) {
        this.$store.commit('accounts/setLastName', value);
      }
    },
    suffix: {
      get() {
        return this.$store.state.accounts.account.suffix;
      },
      set(value) {
        this.$store.commit('accounts/setSuffix', value);
      }
    },
    email: {
      get() {
        return this.$store.state.accounts.account.account_email;
      },
      set(value) {
        this.$store.commit('accounts/setAccountEmail', value);
      }
    },
    phone: {
      get() {
        return this.$store.state.accounts.account.account_phone;
      },
      set(value) {
        this.$store.commit('accounts/setAccountPhone', value);
      }
    },
    type: {
      get() {
        return this.$store.state.accounts.account.account_type;
      }
    },
    self_declared_business_name: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_name;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessName', value);
      }
    },
    self_declared_business_type: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_type;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessType', value);
      }
    },
    self_declared_business_type_other_text: {
      get() {
        return this.$store.state.accounts.account.self_declared_business_type_other_text;
      },
      set(value) {
        this.$store.commit('accounts/setAccountBusinessTypeText', value);
      }
    }
  },

  methods: {
    ...mapActions({
      fetchAccount: 'accounts/fetchAccount',
    }),
    handleSaveClick() {
      if (this.validateEmail(this.email)) {
        this.$store.dispatch('accounts/updateAccount')
          .then(() => {
            this.$store.dispatch('accounts/fetchAccount', { accountUuid: this.accountUuid }).then(() => {
              this.$router.push({ name: 'Account', params: { accountUuid: this.accountUuid } });
            });
          });
      }
    },
    handleCancelClick() {
      this.$router.push({ name: 'Account', params: { accountUuid: this.accountUuid } });
    }
  },

  mounted() {
    this.fetchAccount({ accountUuid: this.accountUuid });
  }

};
</script>

<style lang="scss" scoped>
  h3 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  #account-menu {
     margin-top: 20px;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
</style>
