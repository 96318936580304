<template>
  <div>
    <div v-for="entity in businessEntities" v-bind:key="entity.business_entity_uuid">
      <h2 class="header">
        <router-link class="white-text" :to="{ name: 'BusinessEntity', params: { businessEntityUuid: entity.business_entity_uuid } }">
          {{entity.business_entity_name }}
        </router-link>
      </h2>
      <div class="box">
        <div class="row">
          <div class="col">
            <table class="table table-sm table-borderless">
              <thead>
              <tr>
                <th>Date</th>
                <th>Jurisdiction</th>
                <th>Status</th>
                <th>Fulfillment Level</th>
                <th>API Version</th>
                <th>Salesforce</th>
              </tr>
              </thead>
              <tbody>
                <template v-for="order in orders" >
                  <OrderListItem
                    :key="order.order_uuid"
                    :order="order"
                    :showOrderTasksHelper="true"
                    v-if="order.business_entity_uuid === entity.business_entity_uuid"
                  />
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OrderListItem from '../orders/order-list-item';

export default {
  name: 'AccountOrdersView',

  components: {
    OrderListItem
  },

  computed: {
    ...mapState({
      orders: (state) => state.orders.orders,
      businessEntities: (state) => state.businessEntities.businessEntities
    })
  }

};
</script>

<style scoped>
.white-text{
  color: #FFFFFF;
}
</style>
