<template>
    <div class="menu" v-if="Object.keys(printDeviceInfo).length > 0">
        <div v-for="(device, i) in printDeviceInfo" :key="i">
            <dd>Device {{ i + 1 }}</dd>
            <dt>Platform</dt>
            <dd>{{ device.platform }}</dd>
            <dt>Version</dt>
            <dd>{{ device.version }}</dd>
            <dt>Device Id</dt>
            <dd class="alphanumeric-code">{{ device.deviceId }}</dd>
            <dt>Push Token</dt>
            <dd class="alphanumeric-code">
              <SlotCopy :valueToCopy="device.pushToken">
                {{ device.pushToken | truncate(50) }}
              </SlotCopy>
            </dd>
            <dt>Last Updated</dt>
            <dd>{{ device.updatedDatetime }}</dd>
            <br v-if="parseInt(i) != (Object.keys(printDeviceInfo).length - 1)" />
        </div>
    </div>
    <div class="menu" v-else>
        <dd>Devices</dd>
        <dt>None Registered</dt>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import SlotCopy from "@/components/v2/common/Generics/SlotCopy.vue";

export default {
    name: 'AccountDeviceInfoView',
    props: ['accountUuid'],
    components: {SlotCopy },
    computed: {
        ...mapGetters({
            deviceInfo: 'accountDeviceInfo/getDeviceInfo'
        }),
        printDeviceInfo() {
            return this.deviceInfo
        }
    },

    methods: {
        ...mapActions({
            fetchDeviceInfo: 'accountDeviceInfo/fetchDeviceInfo',
            resetDeviceInfoState: 'accountDeviceInfo/resetDeviceInfoState'
        }),
    },
    mounted() {
        this.fetchDeviceInfo({ accountUuid: this.accountUuid });
    }
};
</script>

<style lang="scss" scoped>
  .alphanumeric-code {
    word-break: break-all
  }
</style>
