// This file contains functions that generate the dynamic urls for the account page
//
// Note: The functions in this file are used in the following files:
//// - ./src/components/accounts/account-side-bar.vue
//// - ./src/components/accounts/AccountDetail.vue
//// - ./src/components/business-entities/business-entity-side-bar.vue

// Define environment-dependent values
const ENVIRONMENT = {
  unit: (isProduction) => (isProduction ? "unit.co" : "s.unit.sh"),
  gcp: (isProduction) =>
    isProduction ? "project=zen-prod" : "project=zen-dev-166315",
  launchDarkly: (isProduction) => (isProduction ? "production" : "dev"),
};

// Define base URLs
const BASE_URLS = {
  clarity: "https://clarity.microsoft.com/projects/view/9o5fv3zt6d",
  sentry: "https://zenbusiness-pbc.sentry.io",
  mixPanel:
    "https://mixpanel.com/project/1468829/view/1394507/app/profile#distinct_id=",
  launchDarkly: "https://app.launchdarkly.com/zen-business",
  unit: "https://app.",
  gcpLogsQuery:
    "https://console.cloud.google.com/logs/query;query=SEARCH%2528%22%60",
  stripe: "https://dashboard.stripe.com/search?query=email:",
};

// Function to generate URL for the MS Clarity dashboard with User UUID. Works in Prod only.
export function dashboardClarityUrl(accountUserUuid) {
  const params = new URLSearchParams({
    date: "Last 30 days",
    CustomUserId: `is;${accountUserUuid}`,
  });
  return `${BASE_URLS.clarity}/dashboard?${params.toString()}`;
}

// Function to generate URL for MS Clarity recordings with User UUID. Works in Prod only.
export function recordingsClarityUrl(accountUserUuid) {
  const params = new URLSearchParams({
    date: "Last 30 days",
    CustomUserId: `is;${accountUserUuid}`,
  });
  return `${BASE_URLS.clarity}/impressions?${params.toString()}`;
}

// Function to generate URL for MS Clarity heatmaps with User UUID. Works in Prod only.
export function heatmapsClarityUrl(accountUserUuid) {
  const params = new URLSearchParams({
    date: "Last 30 days",
    CustomUserId: `is;${accountUserUuid}`,
  });
  return `${BASE_URLS.clarity}/heatmaps?${params.toString()}`;
}

// Function to generate URL for MixPanel activity with User UUID. Works in Prod only.
export function activityMixPanelUrl(accountUserUuid) {
  return `${BASE_URLS.mixPanel}${accountUserUuid}`;
}

// Function to generate URL for Stripe with email. Works in Prod only.
export function merchantStripeUrl(accountEmail) {
  return `${BASE_URLS.stripe}${accountEmail}`;
}

// Function to generate URl for LaunchDarkly contexts with User UUID. Works in Dev and Prod.
export function contextsLaunchDarklyUrl(accountUserUuid, isProduction) {
  const environment = ENVIRONMENT.launchDarkly(isProduction);
  return `${BASE_URLS.launchDarkly}/${environment}/contexts/user/${accountUserUuid}`;
}

// Function to generate URL for Sentry Discover with email And User UUID. Works in Dev and Prod.
export function discoverSentryUrl(accountUserUuid, accountEmail) {
  const params = new URLSearchParams({
    query: `user.display:[${accountUserUuid},${accountEmail}]`,
    statsPeriod: "30d",
    sort: "-timestamp",
  });
  return `${BASE_URLS.sentry}/discover/homepage?${params.toString()}`;
}

// Function to generate URL for Sentry Issues with email. Works in Dev and Prod.
export function issuesSentryUrl(accountEmail) {
  const params = new URLSearchParams({
    query: `user.email:${accountEmail}`,
    statsPeriod: "30d",
  });
  return `${BASE_URLS.sentry}/issues?${params.toString()}`;
}

// Function to generate URL for Unit applications by email. Works in Dev and Prod.
export function applicationsUnitUrl(accountEmail, isProduction) {
  const unitEnvironment = ENVIRONMENT.unit(isProduction);
  const baseUrl = `${BASE_URLS.unit}${unitEnvironment}/applications`;
  const params = new URLSearchParams({
    "app-filter[query]": accountEmail,
  });
  return `${baseUrl}#${params.toString()}`;
}

// Function to generate URL for GCP logs with Email And User UUID. Works in Dev and Prod.
export function logsEmailAndUserUrl(
  accountEmail,
  accountUserUuid,
  isProduction
) {
  const gcpEnvironment = ENVIRONMENT.gcp(isProduction);
  const filterDate = "duration=P2D";
  return `${BASE_URLS.gcpLogsQuery}${accountEmail}%60%22%2529%20OR%20SEARCH%2528%22%60${accountUserUuid}%60%22%2529;${filterDate}?${gcpEnvironment}`;
}

// Function to generate URL for GCP logs with Account UUID. Works in Dev and Prod.
export function logsAccountUuidUrl(accountUuid, isProduction) {
  const gcpEnvironment = ENVIRONMENT.gcp(isProduction);
  const filterDate = "duration=P2D";
  return `${BASE_URLS.gcpLogsQuery}${accountUuid}%60%22%2529;${filterDate}?${gcpEnvironment}`;
}

// Function to generate URL for GCP logs with Email, User UUID, Account UUID. Works in Dev and Prod.
export function logsAllUrl(
  accountEmail,
  accountUserUuid,
  accountUuid,
  isProduction
) {
  const gcpEnvironment = ENVIRONMENT.gcp(isProduction);
  const filterDate = "duration=P2D";
  return `${BASE_URLS.gcpLogsQuery}${accountEmail}%60%22%2529%20OR%20SEARCH%2528%22%60${accountUserUuid}%60%22%2529%20OR%20SEARCH%2528%22%60${accountUuid}%60%22%2529;${filterDate}?${gcpEnvironment}`;
}

// Function to generate URL for GCP logs with Business UUID. Works in Dev and Prod.
export function logsBusinessEntityUuidUrl(isProduction, getBusinessEntityUuid) {
  const gcpEnvironment = ENVIRONMENT.gcp(isProduction);
  const baseUrl = BASE_URLS.gcpLogsQuery;
  const filterDate = "duration=P2D";
  const businessUuid = `${getBusinessEntityUuid}`;
  return `${baseUrl}${businessUuid}%60%22%2529;${filterDate}?${gcpEnvironment}`;
}
