<template>
  <div>
    <div class="menu">
      <dt>Account Holder Name</dt>
      <dd>
        <SlotCopy :valueToCopy="fullName">
          {{ fullName }}
        </SlotCopy>
      </dd>
      <dt>Account Email</dt>
      <dd>
        <SlotCopy :valueToCopy="account.account_email">
          {{ accountEmail | truncate(30) }}
        </SlotCopy>
      </dd>
      <dt>Phone</dt>
      <dd>
        <SlotCopy :valueToCopy="account.account_phone">
          {{ account.account_phone }}
        </SlotCopy>
      </dd>
      <dt>User UUID</dt>
      <dd>
        <SlotCopy :valueToCopy="accountUserUuid">
          {{ accountUserUuid }}
        </SlotCopy>
      </dd>
      <dt>Status</dt>
      <dd class="badge" v-bind:class="status">{{ account.account_status }}</dd>
      <span v-if="account.test_account" class="badge" v-bind:class="testAccount">Test Account</span>
      <span v-if="account.obfuscated" class="badge" v-bind:class="testAccount">Obfuscated</span>
      <dt>Type</dt>
      <dd>
        <div>{{ account.account_type }}</div>
      </dd>
      <dt>Salesforce</dt>
      <dd>
        <a :href="accountSalesforceUrl" target="_blank">Account</a>
        <br />
        <a :href="contactSalesforceUrl" target="_blank">Contact</a>
      </dd>
      <template v-if="isProduction">
        <dt>Microsoft Clarity</dt>
        <dd>
          <a :href="dashboardClarityUrl" target="_blank">Dashboard</a>
          <br />
          <a :href="recordingsClarityUrl" target="_blank">Recordings</a>
          <br />
          <a :href="heatmapsClarityUrl" target="_blank">Heatmaps</a>
        </dd>
        <dt>MixPanel</dt>
        <dd>
          <a :href="activityMixPanelUrl" target="_blank">Activity</a>
        </dd>
        <dt>Stripe</dt>
        <dd>
          <a :href="merchantStripeUrl" target="_blank">Merchant</a>
        </dd>
      </template>
      <dt>LaunchDarkly</dt>
      <dd>
        <a :href="contextsLaunchDarklyUrl" target="_blank">User Contexts</a>
      </dd>
      <dt>Sentry</dt>
      <dd>
        <a :href="discoverSentryUrl" target="_blank">Discover</a>
        <br />
        <a :href="issuesSentryUrl" target="_blank">Issues</a>
      </dd>
      <dt>Unit</dt>
      <dd>
        <a :href="applicationsUnitUrl" target="_blank">Applications</a>
      </dd>
      <dt>GCP Logs</dt>
      <dd>
        <a :href="logsEmailAndUserUrl" target="_blank">Email & User</a>
        <br />
        <a :href="logsAccountUuidUrl" target="_blank">Account</a>
        <br />
        <a :href="logsAllUrl" target="_blank">All</a>
      </dd>
      <div v-if="hasPermission('represent:users')">
        <dt>ZenBusiness.com</dt>
        <form method="POST" :action="oboLoginAction" target="_blank">
          <input type="hidden" name="authorization" :value="accessToken">
          <input type="hidden" name="obo" :value="accountEmail">
          <input type="hidden" name="obo_account_uuid" :value="accountUuid">
          <input type="hidden" name="obo_user_uuid" :value="accountUserUuid">
          <input type="hidden" name="idToken" :value="idToken">
          <input class="btn btn-warning btn-sm" type="submit" value="On-behalf-of">
        </form>
      </div>
    </div>
    <AccountDeviceInfoView :accountUuid=accountUuid></AccountDeviceInfoView>
    <div class="menu" v-if="!isUnisearch">
      <h2>Actions</h2>
      <div class="btn btn-info" @click="handleEditClick">Edit Account</div>
      <div class="btn btn-info" @click="handleCreateOrderClick">Create Order</div>
      <div v-if="hasPermission('write:pricing') || hasPermission('ecat:admin')" class="btn btn-info" @click="handleCreateOrderECATClick">Create Order through eCAT</div>
      <div class="btn btn-info" @click="handleCreateBusinessEntityClick">Create Business Entity</div>
      <div v-if="hasPermission('obfuscate:users') && !account.obfuscated" class="btn btn-danger btn-sm" @click="showObfuscateModal=true">Obfuscate Customer Data</div>
    </div>
    <AccountObfuscationModal v-if="showObfuscateModal" v-on:closeModal="showObfuscateModal=false" />

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import authService from '../../auth/AuthService';
import { hasPermissionMixin } from '../mixins';
import SlotCopy from '@/components/v2/common/Generics/SlotCopy';
import manifest from '../../manifest';
import AccountObfuscationModal from './AccountObfuscationModal'
import AccountDeviceInfoView from '@/v2/account-info/components/AccountDeviceInfoView';
import {
  dashboardClarityUrl,
  recordingsClarityUrl,
  heatmapsClarityUrl,
  activityMixPanelUrl,
  contextsLaunchDarklyUrl,
  discoverSentryUrl,
  issuesSentryUrl,
  applicationsUnitUrl,
  merchantStripeUrl,
  logsEmailAndUserUrl,
  logsAccountUuidUrl,
  logsAllUrl
} from '@/components/utils/dynamicUrls.js';

export default {
  name: 'AccountSideBar',

  components: { AccountObfuscationModal, AccountDeviceInfoView, SlotCopy },

  mixins: [hasPermissionMixin],

  data() {
    return {
      cancelClicked: false,
      showObfuscateModal: false
    };
  },

  computed: {
    ...mapGetters({
      accountUuid: 'accounts/getAccountUuid'
    }),
    ...mapState('accounts', ['account', 'users']),
    isProduction() {
      return manifest.environment === 'production';
    },
    printDeviceInfo() {
      return this.deviceInfo[0]
    },
    // TODO : When multiple users support is implemented , find the user that has the account owner role and return id
    accountUserUuid() {
      // this assumes 1:1 account-user mapping,  will change.
      if (this.users?.length>0) {
        return this.users[0].user_uuid;
      }
      return "-";
    },
    accountEmail() {
      return this.account?.account_email || '';
    },
    status() {
      if (this.account.account_status === 'canceled') {
        return {
          'bg-danger': true
        };
      }
      return {
        'bg-info': true
      };
    },
    testAccount() {
      return {
        'bg-danger': true
      };
    },
    accountSalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.account_sf_id}/view`;
    },
    contactSalesforceUrl() {
      return `${manifest.clients.salesforce.host}lightning/r/${this.account.contact_sf_id}/view`;
    },
    dashboardClarityUrl() {
      return dashboardClarityUrl(this.accountUserUuid);
    },
    recordingsClarityUrl() {
      return recordingsClarityUrl(this.accountUserUuid);
    },
    heatmapsClarityUrl() {
      return heatmapsClarityUrl(this.accountUserUuid);
    },
    activityMixPanelUrl() {
      return activityMixPanelUrl(this.accountUserUuid);
    },
    merchantStripeUrl() {
      return merchantStripeUrl(this.accountEmail);
    },
    contextsLaunchDarklyUrl() {
      return contextsLaunchDarklyUrl(this.accountUserUuid, this.isProduction);
    },
    discoverSentryUrl() {
      return discoverSentryUrl(this.accountUserUuid, this.accountEmail);
    },
    issuesSentryUrl() {
      return issuesSentryUrl(this.accountEmail);
    },
    applicationsUnitUrl() {
      return applicationsUnitUrl(this.accountEmail, this.isProduction);
    },
    logsEmailAndUserUrl() {
      return logsEmailAndUserUrl(this.accountEmail, this.accountUserUuid, this.isProduction);
    },
    logsAccountUuidUrl() {
      return logsAccountUuidUrl(this.accountUuid, this.isProduction);
    },
    logsAllUrl() {
      return logsAllUrl(this.accountEmail, this.accountUserUuid, this.accountUuid, this.isProduction);
    },
    oboLoginAction() {
      const subdomain = this.isProduction ? 'www' : 'www.dev';
      return `https://${subdomain}.zenbusiness.com/d/login-obo`;
    },
    isUnisearch() {
      return this.hasPermission('partner:unisearch');
    },
    accessToken() {
      return authService.getAccessToken();
    },
    idToken() {
      return authService.getIdToken();
    },
    fullName() {
      return [this.account.first_name, this.account.middle_name, this.account.last_name, this.account.suffix].filter(Boolean).join(' ');
    }
  },

  methods: {
    handleEditClick() {
      this.$router.push({ name: 'AccountEdit', params: { accountUuid: this.accountUuid } });
    },
    handleCreateOrderClick() {
      this.$router.push({ name: 'OrderCreate', params: { accountUuid: this.accountUuid } });
    },
    handleCreateOrderECATClick() {
      window.open(`${window.location.origin}/ecomm/account/${this.accountUuid}`)
    },
    handleCreateBusinessEntityClick() {
      this.$router.push({ name: 'BusinessEntityCreate', params: { accountUuid: this.accountUuid } });
    },
    refresh() {
      this.cancelClicked = false;
      this.$store.dispatch('accounts/fetchAccount', { accountUuid: this.accountUuid });
    }
  }
};
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 10pt;
    margin: 5px 0 0 0;
  }
  .menu {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 3px 3px 3px 0 #f8f8f8;
  }
  div .btn {
    width: 100%;
    margin-top: 10px;
  }
  .badge {
     text-transform: capitalize;
   }
  dt {
    font-size: 10pt;
    color: #a9a9a9;
  }
</style>
